* {
    font-family: "Poppins";
  }
  
  body {
    margin: 0;
    padding: 0;
    background-color: #F4F4FA;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  